import React, { useEffect, Fragment } from 'react';
import Body from '../../Components/Interface/Body/Body';
import styles from './Home.module.css';

const Home = props => {

    useEffect(() => {
        createBars();
    },[]);

    const createBars = () => {

        const brand = document.querySelector('.'+styles.brand);
        const body = document.querySelector('.'+styles.body);
        const topWrapper = document.querySelector('.'+styles.topBars);
        const bottomWrapper= document.querySelector('.'+styles.bottomBars);
        const amount = 1000;
        
        for(let i=0; i<amount; i++){

            const bar = document.createElement('div');
            const barBackground = document.createElement('div');
            const width = 100/amount;

            /* bar */
            bar.classList.add(styles.bar);
            bar.style.width = width+'vw';
            bar.style.left = (i*width)+'vw';
            // bar.style.height = (window.screen.height + Math.round(Math.random()*window.screen.height)) + 'px';
            // bar.style.top = Math.round(Math.random()*window.screen.height/2) + 'px';
            /* bar background */
            barBackground.classList.add(styles.barBackground);
            barBackground.style.left = -1*(i*width)+'vw';
            // bar.append(barBackground);

            (i%2 === 0) ? topWrapper.append(bar) : bottomWrapper.append(bar);
        }

        setTimeout(() => {
            body.style.opacity = brand.style.opacity = 1;
            window.onscroll = event => {
                console.log(document.body.scrollTop);
            }
        },1000); 
    }

    // add counter and display a message based on the time user spent figuring things out

    return <Fragment>
        <div className={styles.Home}>
            <div className={styles.amazingBackground}>
                <div className={styles.body}>
                    <div className={styles.wrapper}>
                        <Body>
                            <h1>(k)now</h1>
                            <p></p>
                            {/* <a href="">Button</a> */}
                        </Body>
                    </div>
                </div>
                <div className={styles.bottomBars}></div>
                <div className={styles.topBars}></div>
            </div>
            <div className={styles.brand}>
                <h1>(k)now</h1>
            </div>
        </div>
    </Fragment>
}

export default Home;