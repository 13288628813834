import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as firebase from 'firebase';

firebase.initializeApp({
  apiKey: "AIzaSyCoHhdW7efRozhdQvmco_jKZ025o6ACZ_4",
  authDomain: "k-now-web.firebaseapp.com",
  databaseURL: "https://k-now-web.firebaseio.com",
  projectId: "k-now-web",
  storageBucket: "k-now-web.appspot.com",
  messagingSenderId: "970198189497",
  appId: "1:970198189497:web:79b91bce2ba3f931f42268",
  measurementId: "G-MQ0YDH984S"
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.unregister();
