import React, { Fragment } from 'react';
import styles from './Body.module.css';

const Body = props => {
    return <Fragment>
        <div className={styles.Body}>
            {props.children}
        </div>
    </Fragment>
}

export default Body;